"use client";

import dayjs from "dayjs";
import {
  ReadonlyURLSearchParams,
  usePathname,
  useSearchParams,
} from "next/navigation";
import { FC, useCallback, useEffect } from "react";

import { RAF_STARTED_AT } from "apps/website/constants/storage";

interface IRootSearchParams extends ReadonlyURLSearchParams {
  medium?: "referral"
}

export const RootEffects: FC = () => {
  // TODO: Find cause of page loading half way down, looks like it's a bug
  // related to a CSS property somewhere, have tried 100%'s and overflows
  // as suggested here https://github.com/vercel/next.js/issues/28778
  const resetPageScroll = () => window.scroll({
    top: 0,
    left: 0,
  });

  const pathname = usePathname();
  const searchParams: IRootSearchParams = useSearchParams();

  const setPageId = useCallback(() => {
    document.body.setAttribute("data-page-id", pathname);
  }, [ pathname ]);

  useEffect(() => {

    resetPageScroll();
    setPageId();
  }, [ pathname, searchParams ]);

  useEffect(() => {
    if (!pathname.includes("#")) {
      resetPageScroll();
    }
    setPageId();
  }, []);

  useEffect(() => {
    // Set storage from page load query strings
    if (searchParams.medium === "referral" && localStorage.getItem(RAF_STARTED_AT) === null) {
      localStorage.setItem(RAF_STARTED_AT, dayjs().format("YYYY-MM-DD"));
    }
  }, [ searchParams.medium ]);

  return <></>;
};
