import { Breakpoint, BreakpointMap } from "apps/website/maps/Breakpoint.map";

export const availableTags = [ "ul", "ol" ] as const;

export type AvailableTag = typeof availableTags[number];

export const listStyleMap = {
  default: "list-none",
  numbered: "counter-reset [&_li]:before:counter-increment",
  decimal: "list-decimal",
  disc: "list-disc",
};

export const defaultListColumnsMap = {
  default: "flex flex-wrap",
  noWrap: "flex",
  2: "columns-2 block",
  3: "columns-3 block",
  4: "columns-4 block",
};
export const smallListColumnsMap = {
  default: "sm:flex sm:flex-wrap",
  noWrap: "flex",
  2: "sm:columns-2 sm:block",
  3: "sm:columns-3 sm:block",
  4: "sm:columns-4 sm:block",
};
export const mediumListColumnsMap = {
  default: "md:flex md:flex-wrap",
  noWrap: "flex",
  2: "md:columns-2 md:block",
  3: "md:columns-3 md:block",
  4: "md:columns-4 md:block",
};
export const largeListColumnsMap = {
  default: "lg:flex lg:flex-wrap",
  noWrap: "flex",
  2: "lg:columns-2 lg:block",
  3: "lg:columns-3 lg:block",
  4: "lg:columns-4 lg:block",
};
export const extraLargeListColumnsMap = {
  default: "xl:flex xl:flex-wrap",
  noWrap: "flex",
  2: "xl:columns-2 xl:block",
  3: "xl:columns-3 xl:block",
  4: "xl:columns-4 xl:block",
};
export const twoExtraLargeListColumnsMap = {
  default: "2xl:flex 2x:flex-wrap",
  noWrap: "flex",
  2: "2xl:columns-2 2xl:block",
  3: "2xl:columns-3 2xl:block",
  4: "2xl:columns-4 2xl:block",
};
export const threeExtraLargeListColumnsMap = {
  default: "3xl:flex 2x:flex-wrap",
  noWrap: "flex",
  2: "3xl:columns-2 2xl:block",
  3: "3xl:columns-3 3xl:block",
  4: "3xl:columns-4 3xl:block",
};

export type ListStyle = keyof typeof listStyleMap;
export type ListColumns = "default" | "noWrap" | 2 | 3 | 4;
export type BreakpointListColumns = {
  [key in Breakpoint]?: ListColumns;
};

export const collectiveColumnsClassMap: BreakpointMap = {
  default: defaultListColumnsMap,
  sm: smallListColumnsMap,
  md: mediumListColumnsMap,
  lg: largeListColumnsMap,
  xl: extraLargeListColumnsMap,
  "2xl": twoExtraLargeListColumnsMap,
  "3xl": threeExtraLargeListColumnsMap,
};
