export const STORYBLOK_PAGE_ROOT = "static";
export const STORYBLOK_CUSTOMER_ROOT = "customer";
export const STORYBLOK_ARTICLES_ROOT = "articles/posts";
export const STORYBLOK_FORMS_ROOT = "forms";
export const STORYBLOK_FLOWS_ROOT = `${STORYBLOK_FORMS_ROOT}/flows/`;
export const STORYBLOK_FLOWS_PRETTY_ROOT = `${STORYBLOK_FORMS_ROOT}/`;
export const STORYBLOK_PRODUCTS_ROOT = `${STORYBLOK_PAGE_ROOT}/products`;
export const STORYBLOK_CAT_BREEDS_ROOT = `${STORYBLOK_PAGE_ROOT}/cat-breeds`;
export const STORYBLOK_ARTICLES_PRETTY_ROOT = "articles";
export const STORYBLOK_CAT_BREEDS_PRETTY_ROOT = "cat-breeds";
export const STORYBLOK_PRODUCTS_PRETTY_ROOT = "products";
export const STORYBLOK_ASSETS_PATH = "https://a.storyblok.com";
