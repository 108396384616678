import { FC, ReactNode, memo } from "react";

import * as FlexMap from "apps/website/maps/Flex.map";
import { makeBreakpointClasses } from "apps/website/utils/classes";
import { breakpointFlexClasses } from "apps/website/utils/classes/flex";

import * as ListMap from "./List.map";

export interface IList {
  tag?: ListMap.AvailableTag;
  listStyle?: ListMap.ListStyle;
  justify?: FlexMap.Justify | FlexMap.BreakpointJustify;
  align?: FlexMap.Align | FlexMap.BreakpointAlign;
  direction?: FlexMap.Direction | FlexMap.BreakpointDirection;
  columns?: ListMap.ListColumns | ListMap.BreakpointListColumns;
  component?: string;
  className?: string;
  children: ReactNode;
}

const List: FC<IList> = ({
  tag = "ul",
  listStyle = "default",
  justify = "default",
  align = "default",
  direction = "column",
  component = "List",
  columns = "default",
  className,
  children,
}) => {
  const Tag = tag;
  const computedClasses = makeBreakpointClasses([
    [ columns, ListMap.defaultListColumnsMap, ListMap.collectiveColumnsClassMap ],
    ...breakpointFlexClasses(align, justify, direction),
  ], className);

  return (
    <Tag data-component={component} className={`${computedClasses} ${ListMap.listStyleMap[listStyle]}`}>
      { children }
    </Tag>
  );
};

export default memo(List);
