"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { FC, useCallback, useEffect } from "react";

import { useQueryParams } from "apps/website/hooks/useQueryParams";
import { useAuth } from "@auth/client-sdk-react";

export const PersistEffects: FC = () => {

  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { chainQueryParamsToUrl } = useQueryParams();
  const { accessToken } = useAuth();

  const chainQueryParams = useCallback(() => {
    document.querySelectorAll("a").forEach((link: HTMLAnchorElement) => {
      const href = link.getAttribute("href");
      if (href && ((href.startsWith("/") && !href.startsWith("/manage-plan")) || href.includes("katkin.com"))) {
        link.setAttribute("href", chainQueryParamsToUrl(href));
      }
    });
  }, [ searchParams, pathname, accessToken ]);

  useEffect(() => {
    chainQueryParams();
  }, [ pathname, searchParams, accessToken ]);

  return <></>;
};
