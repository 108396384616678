import { FC, ReactNode, memo } from "react";

import { Theme, themeRootClassMap } from "apps/website/maps/Theme.map";
import * as FlexMap from "apps/website/maps/Flex.map";
import { makeBreakpointClasses } from "apps/website/utils/classes";
import { breakpointFlexClasses } from "apps/website/utils/classes/flex";
import { WithTestID } from "apps/website/types";

export interface IListItem extends WithTestID {
  justify?: FlexMap.Justify | FlexMap.BreakpointJustify;
  align?: FlexMap.Align | FlexMap.BreakpointAlign;
  direction?: FlexMap.Direction | FlexMap.BreakpointDirection;
  className?: string;
  display?: "flex" | "list-item";
  component?: string;
  theme?: Theme;
  children: ReactNode;
  style?: React.CSSProperties;
}

const ListItem: FC<IListItem> = ({
  align = "default",
  justify = "default",
  direction = "row",
  className = "",
  component = "ListItem",
  display = "flex",
  "data-testid": testId,
  theme,
  children,
  style,
}) => {
  const computedClasses = makeBreakpointClasses([
    ...breakpointFlexClasses(align, justify, direction),
  ], className);

  return (
    <li
      data-component={component}
      data-theme={theme}
      data-testid={testId}
      className={`${display} ${computedClasses} ${theme ? themeRootClassMap[theme] : undefined}`}
      style={style}
    >
      { children }
    </li>
  );
};

export default memo(ListItem);
