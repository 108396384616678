"use client";

import { FC, useCallback } from "react";
import { usePostHog } from "posthog-js/react";

import { AuthProvider, useDatadog } from "@auth/client-sdk-react";

import { PublicConfig } from "../config/public";
import { WithChildren } from "../types";

export const WebsiteAuthProvider: FC<WithChildren>
  = ({ children }) => {

    const posthog = usePostHog();
    const datadog = useDatadog();

    const onLogout = useCallback(() => {
      posthog.reset();
      datadog.clearGlobalContext();
    }, [ posthog, datadog ]);

    return (
      <AuthProvider
        onLogout={onLogout}
        authConfig={PublicConfig}
      >
        { children }
      </AuthProvider>
    );
  };
