import { IImageProps } from "apps/website/components/base/Image/Image";
import {
  IStoryBlokAdvancedImages,
  IStoryBlokImage,
} from "apps/website/components/page/api.types";
import { STORYBLOK_ASSETS_PATH } from "apps/website/constants/storyblok";

export enum ImageMax {
  HALF_SCREEN = 1600,
  HALF_CONTAINER_SM = 320,
  HALF_CONTAINER_MD = 384,
  HALF_CONTAINER_LG = 484,
  HALF_CONTAINER_2XL = 716,
  CONTAINER_SM = 640,
  CONTAINER_MD = 768,
  CONTAINER_LG = 968,
  CONTAINER_XL = 1200,
  CONTAINER_2XL = 1432,
  ICON_SM = 100,
  ICON_MD = 200,
}

export const getStoryBlokImageDimensions = (imageUrl: string): { width: number, height: number } => ({
  width: imageUrl ? Number(imageUrl.split("/")[5].split("x")[0]) : 0,
  height: imageUrl ? Number(imageUrl.split("/")[5].split("x")[1]) : 1,
});

export const getStoryBlokImageSrc = (imageSrc: string, getWebP: boolean, maxWidth?: number) => {
  if (!imageSrc) return undefined;
  if (imageSrc.endsWith(".svg")) return imageSrc;
  if (!imageSrc.startsWith(STORYBLOK_ASSETS_PATH)) return imageSrc;
  const widthString = maxWidth ? `${maxWidth}x0` : "1200x0";
  return getWebP ? `${imageSrc}/m/${widthString}` : imageSrc;
};

const getResonsiveSrcWidth = (breakpointMax: number, definedMaxWidth: number, intrinsicWidth: number) => [
  breakpointMax, definedMaxWidth, intrinsicWidth,
].sort((a, b) => a - b)[0];

export interface IStoryBlokImageOptions {
  internal?: boolean;
  width?: number,
  height?: number;
}

export const getStoryBlokImage = (
  image: IStoryBlokImage,
  getWebP = true,
  maxWidth?: number,
  options?: IStoryBlokImageOptions,
): IImageProps => {

  const imageDimensions = (options?.width && options?.height) ?
    { width: options.width, height: options.height } : getStoryBlokImageDimensions(image?.filename);

  return {
    ...imageDimensions,
    src: options?.internal ? image.filename : getStoryBlokImageSrc(image?.filename, getWebP, maxWidth) ?? "",
    alt: image?.alt,
    responsiveSrc: options?.internal ? undefined : {
      extraSmall: getStoryBlokImageSrc(
        image?.filename,
        getWebP,
        getResonsiveSrcWidth(420, maxWidth ?? 420, imageDimensions.width),
      ),
      mobile: getStoryBlokImageSrc(
        image?.filename,
        getWebP,
        getResonsiveSrcWidth(768, maxWidth ?? 768, imageDimensions.width),
      ),
      desktop: getStoryBlokImageSrc(
        image?.filename,
        getWebP,
        getResonsiveSrcWidth(2200, maxWidth ?? 2200, imageDimensions.width),
      ),
      extraLarge: getStoryBlokImageSrc(
        image?.filename,
        getWebP,
        getResonsiveSrcWidth(2500, maxWidth ?? 2500, imageDimensions.width),
      ),
    },
  };
};

export const getStoryBlokAdvancedImage = (image: string, maxWidth: number) => {
  const widthString = maxWidth ? `${maxWidth}x0` : "";
  return {
    ...getStoryBlokImageDimensions(image),
    src: image?.startsWith(STORYBLOK_ASSETS_PATH) ? `${image}/m/${widthString}` : image,
  };
};

export const getStoryBlokAdvancedImages = (images: IStoryBlokAdvancedImages | undefined) => {
  if (!images) return undefined;
  return {
    extraSmall: getStoryBlokAdvancedImage(images.mobileImage ?? "", 420),
    mobile: getStoryBlokAdvancedImage(images.mobileImage ?? "", 768),
    desktop: getStoryBlokAdvancedImage(images.desktopImage ?? "", 2200),
    extraLarge: getStoryBlokAdvancedImage(images.extraLargeDesktopImage ?? "", 2500),
  };
};
